<template>
  <section class="container">
    <!-- <div class="loading-component">
      <FadeLoader
        color="#1890ffff"
        loading="loading"
        :size="500"
        sizeUnit="px"
      />
    </div> -->
    <div>
      <a-row>
        <create-product v-model="value" title="Yangi Mahsulot"></create-product>
        <product-info v-model="product_info"></product-info>
        <text-editor
          v-model="product_info"
          title="Mahsulot jadvali"
        ></text-editor>
      </a-row>
    </div>

    <a-row type="flex" justify="end" class="mt-5">
      <a-button
        size="large"
        @click="createProduct"
        class="bg-success text-white mr-5 mb-5"
        >Qo'shish</a-button
      >
    </a-row>

    <a-row>
      <h4 class="ml-sm-2 ml-md-4 ml-lg-5 font-weight-normal">
        Qo'shimcha rasm qo'shish
      </h4>
      <product-extra-img :productId="productId"></product-extra-img>
    </a-row>
    <modal v-if="inValid" title="Xato" @close="closeModal">
      <p class="text-white bg-danger p-3 mt-3">
        Iltimos product nomlarni, kategoriya, narx va rasmni kiriting
      </p>
    </modal>
  </section>
</template>

<script>
import CreateProduct from '../../components/Products/CreateProduct.vue';
import ProductInfo from '../../components/Products/ProductInfo.vue';
import TextEditor from '../../components/Products/TextEditor.vue';
import productExtraImg from './productExtraImg.vue';
// import { FadeLoader } from '@saeris/vue-spinners';
import { mapActions } from 'vuex';
export default {
  components: {
    CreateProduct,
    ProductInfo,
    TextEditor,
    productExtraImg,
    // FadeLoader,
  },

  data() {
    return {
      productId: '',
      inValid: false,
      value: {
        name: '',
        name_en: '',
        name_ru: '',
        name_uz: '',
        price: 0,
        order: 0,
        category: {
          id: '',
          name: '',
        },
        image: {},
      },
      product_info: {
        info: '',
        info_en: '',
        info_ru: '',
        info_uz: '',
        diametr: '',
        value: '',
      },
    };
  },

  methods: {
    ...mapActions({
      postProduct: 'product/createProduct',
    }),
    closeModal(bool) {
      this.inValid = bool;
    },
    createProduct() {
      for (let i in this.value) {
        if (this.value[i] === '') {
          this.inValid = true;
          return;
        }
      }
      const productObj = {
        name: this.value.name,
        name_en: this.value.name_en,
        name_ru: this.value.name_ru,
        name_uz: this.value.name_uz,
        category: this.value.category,
        order: +this.value.order,
        image: this.value.image.files,
        price: +this.value.price,
        ...this.product_info,
      };
      this.postProduct(productObj)
        .then((res) => {
          this.$notification['success']({
            message: "Muffaqiyatli qo'shildi",
            description: "Siz hozir qo'shimcha Rasm qo'sha olasiz  :)",
          });
          this.value = {
            name: '',
            name_en: '',
            name_ru: '',
            name_uz: '',
            price: 0,
            order: 0,
            category: {
              id: '',
              name: '',
            },
            image: {},
          };

          this.product_info = {
            info: '',
            info_en: '',
            info_ru: '',
            info_uz: '',
            diametr: '',
            value: '',
          };

          this.productId = res.id;
        })
        .catch((error) => {
          this.$notification['error']({
            message: 'Xatolik',
            description: "Product Serverga qo'shilmadi ):" + error.message,
          });
        });
    },
  },
};
</script>

<style scoped>
.loading-component {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}
</style>
